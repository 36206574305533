<template>
  <div>
    <p class="text-2xl mb-6">
      {{
        editing
          ? `Editer l'Univers : ${siteGroup.name}`
          : "Créer un nouvel Univers"
      }}
    </p>
    <v-card class="pt-7 pb-3">
      <v-row class="ma-3">
        <v-card-text>
          <v-form ref="siteForm">
            <v-row>
              <v-col cols="12" sm="8">
                <div class="mb-4 d-flex align-center">
                  <v-text-field
                    outlined
                    v-model="siteGroup.name"
                    dense
                    label="Nom"
                    :rules="[required]"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" sm="8">
                <div class="mb-4 d-flex align-center">
                  <v-text-field
                    v-model="siteGroup.code"
                    outlined
                    dense
                    :rules="[required]"
                    label="Code"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" sm="8">
                <v-autocomplete
                  outlined
                  v-model="siteGroup.sites"
                  :items="siteList"
                  :menu-props="{ maxHeight: '400', zIndex: 13 }"
                  label="Sites"
                  multiple
                  chips
                  dense
                  deletable-chips
                  item-text="name"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  class="me-3 mt-3"
                  @click.native="saveSiteGroup"
                >
                  Enregistrer l'Univers</v-btn
                >
                <v-btn
                  color="secondary"
                  outlined
                  class="mt-3"
                  :to="{ name: 'admin-list-group-site' }"
                >
                  Annuler
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { required, integerValidator } from "@core/utils/validation";
import axios from "@axios";
import router from "@/router";
export default {
  name: "SiteCreateOrUpdate",
  async created() {
    if ("id" in this.$route.params && this.$route.params.id) {
      this.getSiteGroupById(this.$route.params.id);
      this.editing = true;
      this.siteGroup.pk = this.$route.params.id;
    } else {
      this.editing = false;
    }

    this.siteList = await this.$store.dispatch("common/getSiteList");
  },
  data() {
    return {
      required,
      integerValidator,
      editing: false,
      siteList: [],
      siteGroup: {
        pk: -1,
        sites: [],
        name: "",
        code: "",
      },
    };
  },
  methods: {
    async saveSiteGroup() {
      const isFormValid = this.$refs.siteForm.validate();
      if (!isFormValid) return;
      const payload = {
        name: this.siteGroup.name,
        code: this.siteGroup.code,
        sites: this.siteGroup.sites.join(","),
      };

      if (this.editing) {
        const { status } = await axios.put(
          `/site-groups/${this.siteGroup.pk}`,
          payload
        );
        if (status === 200) {
          await router.push({ name: "admin-list-group-site" });
        }
      } else {
        const { status } = await axios.post("/site-groups/", payload);
        if (status === 200) {
          await router.push({ name: "admin-list-group-site" });
        }
      }
    },
    async getSiteGroupById(id) {
      const { status, data } = await axios.get(`/site-groups/${id}`);
      if (status === 200) {
        this.siteGroup = {
          pk: data.pk,
          name: data.name,
          code: data.code,
          sites: data.sites,
        };
      }
    },
    normalize(s) {
      return s
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^a-z0-9 -]/gi, "")
        .replace(/[\s.*]/gi, "");
    },
  },
  watch: {
    "siteGroup.name"(v) {
      // if (!this.siteGroup.code) {
      this.siteGroup.code = this.normalize(v)
        .replace(/[aeiou]/gi, "")
        .substring(0, 5)
        .toLowerCase();
      // }
    },
  },
};
</script>

<style scoped></style>
